import React from "react";
import "./App.css";
import { MdEmail } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import Certificates from "./components/Certificates";
import {motion } from "framer-motion";
import setting from "./motion";

const App = () => {

    

    
    return (
        <div className="app paddings">

            <motion.div
            variants={setting(0.3, 1)}
            initial="hidden"
            whileInView="show"
            viewport={{
                once: true
            }}
            className="yPaddings intro">
                <div>
                    <p className="primaryText">Front End & Blockchain Developer</p>
                    <h2 className="primaryText">Usama Ali</h2>
                    <div className="email">
                        <span>
                            <MdEmail size={25} />
                        </span>
                        <a
                            href="https://mail.google.com/mail/u/0/#inbox?compose=new"
                            target="_blank" rel="noreferrer noopener"
                        >
                            usamaali6584@gmail.com
                        </a>
                    </div>
                    <div className="phone">
                        <span>
                            <BsTelephoneFill size={20} />
                        </span>
                        <span>+92 303 6138452</span>
                    </div>
                    <div className="location">
                        <span>
                            <MdLocationPin size={25} />
                        </span>
                        <p>
                            M&P courier office near U-bank Liaquatpur, district Rahim Yar Khan
                        </p>
                    </div>
                </div>

                <div className="image">
                    <img src="/profilePic.jpg" alt="" />
                </div>
            </motion.div>

            <motion.hr variants={setting(0.3, 0.8)} initial="hidden" animate="show" />

            <motion.div 
            variants={setting(0.6, 1)}
            initial="hidden"
            whileInView="show"
            viewport={{once: true}}
            className="yPaddings summary">
                <p>I am a self-motivated Front-End and Blockchain Developer with a passion for crafting visually appealing and user-centric websites and making optimized smart contracts. Proven ability to collaborate effectively in cross-functional teams and deliver high-quality projects within deadlines. I also do blockchain development, in which I made many smart contracts. I am actively seeking an opportunity to gain hands-on experience in the field.</p>
            </motion.div>

            <motion.div 
            variants={setting(0.8, 1)}
            initial="hidden"
            whileInView="show"
            viewport={{once: true}}
            className="skills">
                <span className="primaryText">Programming Skills</span>
                <hr />
                <p>HTML, CSS, SCSS, JavaScript, Reacr.Js, Solidity, Smart Contracts development</p>
            </motion.div>

            <motion.div 
            variants={setting(1, 1)}
            initial="hidden"
            whileInView="show"
            viewport={{once: true}}
            className="projects yPaddings">
                <p className="primaryText">Projects</p>
                <hr />
                <p>I made many real-life projects with HTML, css, JavaScript, and react.Js. Some of my projects are following, please see these projects before making a decision. </p>
                <ul className="project-links">
                    <li><a href="https://usama-fitness-app.pages.dev/" target="_blank" rel="noreferrer noopener">Fitness App</a></li>
                    <li><a href="https://usama-dashboard.pages.dev/dashboard" target="_blank" rel="noreferrer noopener">Admin dashboard App</a></li>
                    <li><a href="https://usama-portfolio-app.pages.dev/" target="_blank" rel="noreferrer noopener">Portfolio App</a></li>
                    <li><a href="https://usama-realstate.pages.dev/" target="_blank" rel="noreferrer noopener">Real state App</a></li>
                    <li><a href="https://usama-todo-app.pages.dev/" target="_blank" rel="noreferrer noopener">ToDo App</a></li>
                </ul>
            </motion.div>

            <Certificates/>

            <motion.div 
            variants={setting(0.3, 1)}
            initial="hidden"
            whileInView="show"
            viewport={{once: true}}
            className="future-goals yPaddings">
                <span className="primaryText ">Future Goals</span>
                <hr />
                <p>My current future goals revolve around learning the following:</p>
                <ul>
                    <li>Solidity</li>
                    <li>Blockchain development</li>
                    <li>Smart contracts in ethereum</li>
                </ul>
            </motion.div>
        </div>
    );
};

export default App;
