const setting = (delay, duration) => ({
    hidden: {
        y: "100px",
        opacity: 0
    },
    show: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            delay,
            duration,
            ease: 'easeOut'
        }
    }
})

export default setting;