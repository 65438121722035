import React from 'react'
import "./Certificates.css"
import Slider from "react-slick";
import {motion} from "framer-motion"
import setting from '../motion';

const Certificates = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        touchMove: true,
        useCSS: true,
      };

  return (
    <motion.div 
    variants={setting(1.7, 1)}
    initial="hidden"
    animate = "show"
    className="certificates">
        <span className="primaryText">Certificates</span>
        <hr />
    <Slider
    {...settings}
    className='slider-box'
    >
        <div className="cert-box">
            <img src="/cert1.png" alt="" />
            <p className="primaryText">Interactivity with JavaScript</p>
        </div>
        <div className="cert-box">
            <img src="/cert2.png" alt="" />
            <p className="primaryText">Programming with JavaScript </p>
        </div>
        <div className="cert-box">
            <img src="/cert3.png" alt="" />
            <p className="primaryText">HTML, css, and JavaScript </p>
        </div>
        <div className="cert-box">
            <img src="/cert4.png" alt="" />
            <p className="primaryText">JavaScript & JQuery </p>
        </div>
        <div className="cert-box">
            <img src="/cert5.png" alt="" />
            <p className="primaryText">Effective time management </p>
        </div>

    </Slider>
    </motion.div>
  )
}

export default Certificates
